/** @flow
 * StratoDem Analytics : join-the-team
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import React from 'react';

import {Layout, SEO} from '../../components';

import './join-the-team.scss';

export default class WebDevelopmentIntern extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Web Development Intern"
          keywords={['stratodem analytics']}
          description="Web Development Intern"
        />
        <div className="sd-section join-the-team posting">
          <div className="inner">
            <h3>Web Development Intern</h3>

            <h4>About this job</h4>
            <p>
              StratoDem Analytics is currently seeking Web Development Interns who will help
              design and build data analytics applications for the real estate field. This
              position is for someone seeking to work in a remote environment to design and
              implement analytics-oriented web applications.
            </p>

            <h4>The company: StratoDem Analytics</h4>
            <p>
              StratoDem Analytics is a data science firm building predictive models on massive
              data for better US local-market knowledge. We help some of the
              largest private equity funds and real estate organizations in the US make better
              portfolio-level and business decisions based on a fundamentally better understanding
              of local-market economic risk and demographic outlooks.
            </p>

            <h4>Responsibilities</h4>
            <p>
              This paid internship involves:
            </p>
            <ul>
              <li>Designing and developing as a team contributor to StratoDem Analytics data and research products</li>
              <li>Learning and sharing new visualization tech/strategies with the StratoDem Analytics team</li>
              <li>Efficiently bringing ideas from concept to production via sketches and diagrams</li>
              <li>Building within, and improving, the StratoDem Analytics analytics pipeline</li>
              <li>Writing clean, fully-tested and well-documented code in Python, JavaScript and/or CSS</li>
              <li>Leveraging pre-existing internal and external code to deliver different products each month (e.g. one month may be a mapping dashboard, the next may be a portfolio analysis engine)</li>
              <li>Working directly with clients to identify pain points and opportunities in pre-existing analytics pipelines and build or improve StratoDem Analytics products</li>
            </ul>

            <h4>Qualifications</h4>
            <p>
              The ideal candidate for this role has Python expertise and experience with at least some of the following:
            </p>
            <ul>
              <li>Python server-side frameworks such as Django or Flask</li>
              <li>Design tools such as Sketch or Figma</li>
              <li>CSS/SCSS/SASS for styling web applications</li>
              <li>Git and GitHub workflows in a remote team environment</li>
              <li>SQL queries and relational databases</li>
              <li>pandas and NumPy</li>
            </ul>

            <p>To apply, reach out at <a href="mailto:jobs@stratodem.com">jobs@stratodem.com</a></p>
          </div>
        </div>
      </Layout>);
  }
}
